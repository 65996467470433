import Cookies from "js-cookie";

document.addEventListener("DOMContentLoaded", function() {
  var tmp_cookie = Cookies.get();

  if ( document.getElementsByClassName("account-wallet") && document.getElementsByClassName("account-wallet")[0] ) {
    if ( tmp_cookie.account_wallet_amount > 0 ) {
      document.getElementsByClassName("account-wallet")[0].classList.add("new");
    } else {
      document.getElementsByClassName("account-wallet")[0].classList.remove("new");
    }
  }

  if ( document.getElementsByClassName("account-referral-page")[0]) {
    if ( tmp_cookie.account_referral_link ) {
      document.getElementsByClassName("account-referral-page")[0].classList.remove("hidden");
    }
  }

  if (tmp_cookie.account_fname) {
    document.body.classList.add('logged-in');
  } else {
    document.body.classList.remove('logged-in');
  }

});
