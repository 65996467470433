import Product from "./_product";
import axios from "axios";
import _createElementFromHTML from "Utilities";
import _analytics from "../../shared/_analytics";

class CartOverlay {
  constructor(el, { config }) {
    this.config = config;
    this.cartDom = typeof el === "string" ? document.querySelector(el) : el;
    this.hideCartTimeOut = null;
    this.cartData = null;
    this.oldCartData = null;
  }

  setCartData(data) {
    try {
      this.oldCartData = JSON.parse(JSON.stringify(this.cartData));
    } catch (err) {
      console.error("setCartData: ", err);
    }
    this.cartData = data;
  }

  addMarginToProductListWhenScrollbarShow() {
    const productList = this.cartDom.querySelector(
      ".Cart__overlay-items-list--js"
    );
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (productList.scrollHeight > productList.clientHeight) {
      productList.style.marginRight = "-20px";
      productList.style.paddingRight = isSafari ? "20px" : "7px";
    } else {
      productList.style.marginRight = "0px";
      productList.style.paddingRight = "0px";
    }
  }

  applyDiscountCode(discount_code, cb) {
    if (!discount_code) return;
    const cartItems = `/${this.config.region}/cart/get_cart_ajax?1=1`;
    axios
      .get(cartItems, {
        params: {
          discount_code
        }
      })
      .then((res) => {
        if (cb) cb(res);
      });
  }

  applyAffiliate(referral_code, cb) {
    if (!referral_code) return;

    const form = new FormData();
    form.append("referral", referral_code);
    axios
      .post(
        window.settings.yoursole_url +
          "/" +
          window.settings.region +
          "/affiliate",
        form,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest"
          }
        }
      )
      .then((res) => {
        if (cb) cb(res);
      });
  }

  getCart(data, cb) {
    const that = this;
    if (data) {
      this.renderCart(data);
      if (cb) cb(data);
    } else {
      var cartItems = "/" + this.config.region + "/cart/get_cart_ajax?1=1";
      let discount_code = document.getElementById("cart-overlay-discount-code");
      let mv_pc = document.getElementById("cart-overlay-source");
      let mailchimp_campaign_id = document.getElementById(
        "cart-overlay-mailchimp-campaign-id"
      );
      if (discount_code && discount_code.value) {
        cartItems = cartItems + "&discount_code=" + discount_code.value;
      }
      if (mv_pc && mv_pc.value) {
        cartItems = cartItems + "&mv_pc=" + mv_pc.value;
      }
      if (mailchimp_campaign_id && mailchimp_campaign_id.value) {
        cartItems =
          cartItems + "&mailchimp_campaign_id=" + mailchimp_campaign_id.value;
      }

      axios.get(cartItems).then((result) => {
        this.renderCart(result.data);
        if (cb) cb(result.data);
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("affiliate")) {
          that.applyAffiliate(urlParams.get("affiliate"));
        }
      });
    }
  }

  isPromoCodeUpdated() {
    if (!this.oldCartData) return false;
    if (
      this.oldCartData.summary &&
      this.oldCartData.summary.free_shipping_notice &&
      this.oldCartData.summary.free_shipping_notice.substring(40) !=
        this.cartData.summary.free_shipping_notice.substring(40)
    ) {
      if (location.pathname.indexOf("/cart") >= 0) return true;
    }
    return false;
  }

  updateCartIconLink() {
    // Add cart link on larger screen for easier access to checkout
    let cartTarget = document.querySelector('.sole-mainnav .cart-activation');
    if (document.querySelector('.mid-nav').offsetParent != null) {
      cartTarget.setAttribute('href', cartTarget.getAttribute('data-href'));
    } else {
      cartTarget.setAttribute('href', '#');
    }
  }

  updateCartDom() {
    this.updateCartIconLink();
    this.updateCartBubble();
    this.updateCartTotal();
    this.renderItemList();

    if (this.cartData.summary) {
      if (this.cartData.summary.free_shipping_notice) {
        this.getCartNotice();
      } else {
        const shippingStatus = this.cartDom.querySelector(
          ".Cart__overlay-free-shipping-qualification--js"
        );
        shippingStatus.classList.add("d-none");
      }
    }

    if (parseFloat(this.cartData.summary.discount.replace("$", ""))) {
      this.cartDom
        .querySelector(".Cart__overlay-supporting-total--js")
        .classList.remove("hidden");
    } else {
      this.cartDom
        .querySelector(".Cart__overlay-supporting-total--js")
        .classList.add("hidden");
    }
    if (this.isProductInCart()) {
      this.cartDom
        .querySelector(".Cart__overlay-btn--checkout-js")
        .classList.remove("disabled");
    } else {
      this.cartDom
        .querySelector(".Cart__overlay-btn--checkout-js")
        .classList.add("disabled");
    }
    this.cartDom.querySelector(".Cart__overlay-subtotal-num--js").innerHTML =
      this.cartData.summary.subtotal;
    this.cartDom.querySelector(".Cart__overlay-discount-num--js").innerHTML =
      "– " + this.cartData.summary.discount;
    this.cartDom.querySelector(".Cart__overlay-curtotal-num--js").innerHTML =
      this.cartData.summary.current_total;
    this.prepareCartOverlayAction();
    this.addMarginToProductListWhenScrollbarShow();
  }

  updateCartRight() {
    if (window.update_cart_summary) {
      if (!document.querySelector(".cart-right .shipping_cost")) {
        window.update_cart_summary(this.cartData.summary);
      }
    }
  }

  updateCartBubble() {
    document.querySelectorAll('.cart-activation').forEach(bubble => {
      bubble.style.setProperty('--items', this.cartData.summary.total_items);
      bubble.style.setProperty('--has-items', this.cartData.summary.total_items ? 1 : 0);
      if (this.cartData.summary.total_items > 0) {
        bubble.classList.add('display-counter');
      } else {
        bubble.classList.remove('display-counter');
      }
    });
  }

  updateCartTotal() {
    this.cartDom.querySelector(
      ".Cart__overlay-items-total-quantity--js"
    ).innerHTML = " (" + this.cartData.summary.total_items + ")"; // extra space intended
  }
  // Calculate Free Shipping with Proper Styling
  getCartNotice() {
    var shippingStatus = this.cartDom.querySelector(
      ".Cart__overlay-free-shipping-qualification--js"
    );
    shippingStatus.classList.remove("d-none");
    shippingStatus.innerHTML = this.cartData.summary.free_shipping_notice;
    if (
      (this.cartData.summary.free_shipping_notice.indexOf("Spend $") >= 0 &&
        this.cartData.summary.free_shipping_notice.indexOf(
          "to qualify for free standard shipping!"
        ) >= 0) ||
      (this.cartData.summary.free_shipping_notice.indexOf("You're $") >= 0 &&
        this.cartData.summary.free_shipping_notice.indexOf(
          " away from free shipping!"
        ) >= 0) ||
      this.cartData.summary.free_shipping_notice.indexOf(
        "Double up on footbeds to get free shipping."
      ) >= 0 ||
      (this.cartData.summary.free_shipping_notice.indexOf("Add") >= 0 &&
        this.cartData.summary.free_shipping_notice.indexOf(
          " to qualify for "
        ) >= 0) ||
      (this.cartData.summary.free_shipping_notice.indexOf("Add") >= 0 &&
        this.cartData.summary.free_shipping_notice.indexOf("% off.") >= 0)
    ) {
      shippingStatus.classList.add("free-shipping-red");
    } else {
      shippingStatus.classList.remove("free-shipping-red");
    }
  }

  showCart() {
    document
      .querySelector(".Cart__overlay-toggle-div--js")
      .classList.remove("hidden");
    this.addMarginToProductListWhenScrollbarShow();
    _analytics.viewCart(this.cartData.cart);
  }

  hideCart() {
    document
      .querySelector(".Cart__overlay-toggle-div--js")
      .classList.add("hidden");
  }

  delayHideCart() {
    const that = this;
    window.__hideCart = true;
    this.hideCartTimeOut = setTimeout(function () {
      if (window.__hideCart) that.hideCart();
    }, 1000);
  }

  showCartforSecond(second) {
    const that = this;
    setTimeout(function () {
      if (window.__hideCart) that.hideCart();
    }, second * 1000);
  }

  clearHideCartProcess() {
    window.__hideCart = false;
    clearTimeout(this.hideCartTimeOut);
  }

  hideCartWhenClickOutSide() {
    const that = this;
    function ignoreCheck(e) {
      const ignoreList = [
        ".cart-menu",
        "#cart-overlay",
        ".Cart__overlay-product-qandp",
        ".cart-activation"
      ];
      let isIgnored = false;
      ignoreList.forEach((item) => {
        if (e.target.closest(item)) {
          isIgnored = true;
          that.clearHideCartProcess();
          that.showCartforSecond(3);
        }
      });
      if (!isIgnored) that.hideCart();
    }
    document.addEventListener("click", (e) => ignoreCheck(e));
  }

  prepareCartOverlayAction() {
    const that = this;
    const cartTarget = document.querySelector(".cart-activation");
    cartTarget.addEventListener("mouseenter", e => {
      that.updateCartIconLink();
      // If mid-nav is hidden (on tablet screen or smaller), then do anothing
      if (document.querySelector('.mid-nav').offsetParent === null) return;
      that.showCart();
      that.clearHideCartProcess();
    });
    cartTarget.addEventListener("click", () => {
      that.showCart();
      that.delayHideCart();
    });
    that.cartDom
      .querySelector(".Cart__overlay-container")
      .addEventListener("mouseenter", () => that.clearHideCartProcess());
    that.cartDom
      .querySelector(".Cart__overlay-container")
      .addEventListener("mouseleave", () => that.delayHideCart());
    that.cartDom.querySelectorAll(".Cart__overlay-close--js").forEach((el) => {
      function hideCartProcess() {
        window.preventNavbarFromOpen = 1;
        setTimeout(function () {
          window.preventNavbarFromOpen = 0;
        }, 500);
        that.hideCart();
      }
      el.addEventListener("click", hideCartProcess);
    });
    this.hideCartWhenClickOutSide();
    // Hide cart when hover over other items in navigation
    document.querySelectorAll('nav .nav-item').forEach(navItem => {
      navItem.addEventListener('mouseenter', function(e) {
        if (!e.target.querySelector('.cart-activation')) {
          that.hideCart();
        }
      })
    });
  }

  renderItemList() {
    const that = this;
    const productList = this.cartDom.querySelector(
      ".Cart__overlay-items-list--js"
    );
    productList.innerHTML = "";
    this.cartData.cart.forEach((product, productIndex) => {
      let newProduct = new Product({
        product,
        cart: that,
        config: that.config,
        index: productIndex
      });
      productList.append(newProduct.renderProduct());
      if (this.config.disableAddRemove)
        newProduct.disableAddRemove(this.config.disableAddRemove);
    });
  }

  isProductInCart() {
    return this.cartData.cart && this.cartData.cart.length > 0;
  }

  renderCart(data) {
    if (data) this.setCartData(data);
    if (this.isPromoCodeUpdated()) {
      location.reload();
    }
    if (!this.cartDom.querySelector(".Cart__overlay-toggle-div--js")) {
      const cartString = `
      <div class="Cart__overlay Cart__overlay-position Cart__overlay-toggle-div Cart__overlay-toggle-div--js hidden">
        <div class="position-absolute Cart__overlay-toggle-div">
          <div class="Cart__overlay-container container bg-white p-4 bg-white">
            <div class="Cart__overlay-top d-flex flex-row">
              <div class="Cart__overlay-title Cart__overlay-title--font text-uppercase font-weight-bold mr-1">Added to cart</div>
              <div class="Cart__overlay-items-total-quantity Cart__overlay-items-total-quantity--font Cart__overlay-items-total-quantity--js font-weight-bold text-secondary"></div>
              <div class="Cart__overlay-close ml-auto">
                <a title="Close" class="Cart__overlay-close--js m-0 ml-auto clickable" href="javascript:void(0)">
                  <img src="${
                    this.config.media_root
                  }/ysdotcom/images/icons/x.svg" height="25" width="20" />
                </a>
              </div>
            </div>
            <div class="Cart__overlay-items-list Cart__overlay-items-list--js Cart__overlay-items-list--layout Cart__overlay-items-list--overflow d-flex flex-column">
              <div class="d-flex justify-content-center w-100"><img src="${
                this.config.media_root
              }/ysdotcom/images/icons/loading1.svg" width="100" height="100"/></div>
            </div>
            <div class="Cart__overlay-free-shipping-qualification Cart__overlay-free-shipping-qualification--js mt-3 pt-2 pb-2 text-center font-weight-bold">
            </div>
            <hr class="Cart__overlay-hr--color">
            <div class="Cart__overlay-supporting-total--js hidden">
              <div class="Cart__overlay-subtotal d-flex mt-2 mb-2">
                <div class="Cart__overlay-subtotal-text--font">Subtotal:</div>
                <div class="Cart__overlay-subtotal-num Cart__overlay-subtotal-num--font ml-auto d-flex flex-row">
                  <div class="Cart__overlay-subtotal-num--js">${
                    this.config.currency_symbol
                  } 0.00</div>
                  <div class="font-weight-normal pl-1 pt-1 d-flex align-items-center small">${
                    this.config.currency
                  }</div>
                </div>
              </div>
              <div class="Cart__overlay-discount d-flex mt-2 mb-2">
                <div class="Cart__overlay-subtotal-text--font">Discount/Gift Card:</div>
                <div class="Cart__overlay-discount-num Cart__overlay-subtotal-num--font ml-auto Cart__overlay-regprice-discounted text-decoration-none d-flex flex-row">
                  <div class="Cart__overlay-discount-num--js">${
                    this.config.currency_symbol
                  } 0.00</div>
                  <div class="font-weight-normal pl-1 d-flex align-items-center small">${
                    this.config.currency
                  }</div>
                </div>
              </div>
            </div>
            <div class="Cart__overlay-curtotal d-flex mt-2 mb-2">
              <div class="Cart__overlay-subtotal-text--font font-weight-bold">Total (before shipping/tax):</div>
              <div class="Cart__overlay-curtotal-num Cart__overlay-subtotal-num--font ml-auto d-flex flex-row">
                <div class="Cart__overlay-curtotal-num--js font-weight-bold">${
                  this.config.currency_symbol
                } 0.00</div>
                <div class="font-weight-normal pl-1 d-flex align-items-center small">${
                  this.config.currency
                }</div>
              </div>
            </div>
            <div class="Cart__overlay-bottom mt-3">
              <div class="d-flex flex-row">
    <a title="Close" class="popup-close--js" href="javascript:void(0)">
    <a class="btn btn-primary rounded-0 border-0 bg-dark text-white font-weight-bold text-uppercase w-100 ml-0 mr-1 p-3 Cart__overlay-btn--font d-flex align-items-center justify-content-center Cart__overlay-close--js" href="javascript:void(0)">Keep shopping</a>
                <a
                  href="${this.config.yoursole_url}/${
        this.config.region
      }/shipping"
                  class="btn btn-primary rounded-0 border-0 text-white font-weight-bold text-uppercase w-100 ml-1 mr-0 p-3 Cart__overlay-btn--checkout Cart__overlay-btn--checkout-js Cart__overlay-btn--font Cart__overlay-btn--color d-flex align-items-center justify-content-center ${
                    this.isProductInCart() ? "" : "disabled"
                  }"
                >
                  Checkout
                </a>
              </div>
            </div>
            <div class="Cart__overlay-shipping-notice Cart__overlay-shipping-notice--font text-center pl-2 pr-2 pt-3 pb-0 ${
              this.config.free_shipping_amount === 0 ? "d-none" : ""
            }">
              <div>* ${ this.config.free_shipping_login }
              </div>
            </div>
          </div>
        </div>
      </div>
      `;
      this.cartDom.append(_createElementFromHTML(cartString));
    }
    this.updateCartDom();
    this.updateCartRight();
    return this.cartDom;
  }
}

export default CartOverlay;
