import Cookies from "js-cookie";

var isCurrentBlocked = window.disable_mailing_list_preview || false;
var BREAK_POINT = 768;

function checkURL() {
  if (isCurrentBlocked) return isCurrentBlocked;
  isCurrentBlocked = window.location.href.indexOf('madeforyou') > 1 ? true : false;
}

function openMailingList() {
  checkURL();
  if (!isCurrentBlocked) {
    $("#mailing-list-popup").modal('show');
    document.getElementById('mailing__popup-email-input').focus(); // Set focus to email input on pop-up after opening
  }
  Cookies.set('overlay_displayed', 1, { secure: true, expires: 730, path: '/' });
}

function centerMailingListPopUp() {
  $("#mailing-list-popup").css('left', 'calc(50% - 165px)');
}

function resetPositionOfMailingListPopUp() {
  $("#mailing-list-popup").css('left', '50px');
}

function makeModalScrollable() {
  document.querySelector('body').classList.remove('modal-open');
}

$(document).mouseup(function (e) {
  var mailingListPopup = $("#mailing-list-popup");

  if (
    !mailingListPopup.is(e.target) &&
    mailingListPopup.has(e.target).length === 0
  ) {
    if (
      document
        .querySelector("#mailing-list-popup")
        .classList.contains("show")
    ) {
      $("#mailing-list-popup").modal('hide');
    }
  }
});

$(".mailing-list-close").click(function (event) {
  event.preventDefault();
  $("#mailing-list-popup").modal('hide');
});

$('#mailing-list-form input[type="submit"]').on("click", function () {
  $(this).closest("form").find("[required]").addClass("required");
});

$("#mailing-list-form").on("submit", function (e) {
  e.preventDefault();
  $.ajax({
    url: window.mailing_list.subscribe_url,
    type: "GET",
    data: $("#mailing-list-popup form").serialize(),
    success: function (r) {
      $('#mailing-list-popup .modal-title').remove();
      $("#mailing-list-form").html(r);
      setTimeout(function () {
        $("#mailing-list-popup").modal('hide');
      }, 7000);
    },
    error: function (r) {
      $('#mailing-list-popup .modal-title').remove();
      $("#mailing-list-form").html(r);
    }
  });
});


$(document).ready(function () {
  // disable sign-up overlay for this session
  if (window.mailing_list && window.mailing_list.disable_signup) {
    Cookies.set("overlay_displayed", 1, {
      secure: true,
      expires: 30,
      path: "/"
    });
    Cookies.set("ml_prv", 1, {
      secure: true,
      expires: 30,
      path: "/"
    });
  }
});

// Open mailing list
function openMailingListInstantly() {
  Cookies.remove('overlay_displayed');
  showMailingListPopUp()
}

// mailing-list-preview
function openMailingListPreview() {
  $("#mailing-list-preview").on('shown.bs.modal', function() {
    $(document).off('focusin.modal');
  });

  checkURL();

  if (screen.width <= 768 && !isCurrentBlocked) {
    if (!Cookies.get("ml_prv")) {
      $('#mailing-list-preview').modal('show');
      makeModalScrollable();
    };
  } else {
    $('#mailing-list-preview').modal('hide');
  };
};

function showMailingListPopUp() {
  $("#mailing-list-preview").modal('hide');
  // Set cookie to not show the popup after click
  Cookies.set("ml_prv", 1, {
    secure: true,
    expires: 30,
    path: "/"
  });
  if (screen.width <= BREAK_POINT) centerMailingListPopUp();
  if (screen.width > BREAK_POINT) resetPositionOfMailingListPopUp();
  openMailingList()
  $("#mailing-list-popup").css('padding-right', '0 !important;');
}

document.addEventListener('DOMContentLoaded', function() {
  document.getElementById('mailing-list-preview').addEventListener('click', showMailingListPopUp);
  // Because this is user activate action, we should remove the cookie preventing the popup to show.
  document.querySelector("input#mce-EMAIL").addEventListener('click', openMailingListInstantly);
  if (document.querySelector("button#signup")) document.querySelector("button#signup").addEventListener('click', openMailingListInstantly);
  document.querySelectorAll(".open-mailing-list-container").forEach(el => el.addEventListener('click', openMailingListInstantly));
  $('#mailing-list-popup').on('shown.bs.modal', makeModalScrollable);
  $('#mailing-list-popup').on('hidden.bs.modal', resetPositionOfMailingListPopUp);
  window.openMailingListInstantly = openMailingListInstantly;
  setTimeout(openMailingListPreview, 7000);
});
